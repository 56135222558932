import React from 'react'

interface EyeButtonProps {
  setShowEye: Function,
  showEye: boolean,
  styles: string,
}
export default function EyeButton({setShowEye, showEye, styles}: EyeButtonProps) {
  return (
    <button
      type="button"
      onClick={() => setShowEye(!showEye)}
      className={`absolute inset-y-0 right-3 flex items-center cursor-pointer bg-[#313139] z-999 h-[34px] w-[34px] rounded-lg justify-center ${styles} `}
    >
      {!showEye ? (
        <img
          src="/icons/openEye.svg"
          alt="open eye"
          width={25}
          height={25}
        />
      ) : (
        <img
          src="/icons/closeEye.svg"
          alt="close eye"
          width={25}
          height={25}
        />
      )}
    </button>
  )
}
