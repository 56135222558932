import {Director, Media, View} from "@millicast/sdk";
import {MutableRefObject} from "react";
import {useEffect} from "react";

export const millicast = async (dolbyConfig: any, videoEl:  MutableRefObject<any>) => {

    const streamName = dolbyConfig?.streamName;
    console.log("$43", dolbyConfig)
    // Create callback to generate a new token
    const tokenGenerator = () =>
        Director.getSubscriber({
            streamName: dolbyConfig?.streamName,
            streamAccountId: dolbyConfig?.accountId,
            subscriberToken: dolbyConfig?.token, // Optional: This token is needed if you're subscribing to a secure stream,
            // This token should be provided by the publish owner.
        });
    
    // // Create Millicast instance
    const view = new View(streamName, tokenGenerator);

    view.on('track', (event) => {
        console.log('track', event)

        addStreamToYourVideoTag(event.streams[0]);
        return (event.streams[0])
    });



    function addStreamToYourVideoTag(elem: MediaStream) {
        console.log('elem', elem)
        if (elem){
        
        videoEl.current.srcObject = elem;
        console.log('videoEl', videoEl.current.srcObject)
        videoEl.current.autoplay = true;
        videoEl.current.controls = false;
        }
    }

    const options = {
        disableVideo: false,
        disableAudio: false,
        bandwidth: 0,
    };

    try {
        await view.connect(options);
        // console.log('connected')
    } catch (e) {
        console.error('Connection failed, handle error', e);
        view.reconnect();
    }
}
