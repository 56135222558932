import React from 'react';

interface IStreamState {
  streamName: string;
  streamState: string;
}

export default function StreamHeader({
  streamName,
  streamState,
}: Readonly<IStreamState>) {
  return (
    <div className="flex flex-row justify-between items-center px-4 py-4 bg-[#313139] border-t-[#44444D] border-t">
      <p className="text-[#FB6520] capitalize">{streamName}</p>
      <div className="px-2 py-1 bg-[#2DBF81] rounded-md">
        <p>{streamState}</p>
      </div>
    </div>
  );
}
