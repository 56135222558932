import React from 'react';

interface FormTitleProps {
  text: string;
}

export default function FormTitle({ text }: Readonly<FormTitleProps>) {
  return (
    <div className="mb-2">
      <p className="title-24-rf text-center" style={{ '--title-24-rf-text-color': 'var(--Swiss-Coffee)' }}>
        {text}
      </p>
    </div>
  );
}

