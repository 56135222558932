import { v4 as uuidv4 } from 'uuid';

export const correlationId = uuidv4();

export const authSteps = {
  LOGIN: 'login',
  NEW: 'new',
  CHALLENGE: 'challenge',
  FORGET: 'forget',
  RESEND: 'resend',
};

export const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const joinPayload = { type: 'JOIN_REQUEST_INTENT', correlationId };
export const leavePayload = { type: 'USER_LEAVE_ROOM', correlationId };

export const passwordConditionsArray = [
  '8 characters;',
  'one lowercase character;',
  'one uppercase character;',
  'one special character;',
  'one number'
]
