import { createContext } from 'react';
import { IStreamContext } from 'types/context';

const streamContextDefaults: IStreamContext = {
  setStreamId: () => null,
  streamId: null,
  setStreamName: () => null,
  streamName: null,
  setStreamState: () => null,
  streamState: null,
  setZegoCloudCred: () => null,
  zegoCloudCred: null,
  setDolbyStreamingConfig: () => null,
  dolbyStreamingConfig: null,
  setOrganizer: () => null,
  organizer: null,
  setRoomId: () => null,
  roomId: null,
  setSessionData: () => null,
  sessionData: null,
  setRoomData: () => null,
  roomData: null,
  setMicrophoneDevices: () => null,
  microphoneDevices: null,
  setSpeakerDevices: () => null,
  speakerDevices: null,
  setCameraDevices: () => null,
  cameraDevices: null,
  setSpeakerDeviceSelected: () => null,
  speakerDeviceSelected: null,
  setMicrophoneDeviceSelected: () => null,
  cameraDeviceSelected: null,
  setCameraDeviceSelected: () => null,
  microphoneDeviceSelected: null,
  setMuteMicrophone: () => false,
  muteMicrophone: false,
  setMuteCamera: () => false,
  muteCamera: false,
  setMuteSpeakers: () => false,
  muteSpeakers: false,
  setAudioElement: () => null,
  audioElement: null,
  setLocalStream: () => null,
  localStream: null,
  setStreamIDs: () => null,
  streamIDs: null,
  setLocalVideoRef: () => null,
  localVideoRef: null,
  setRemoteStreams: () => null,
  remoteStreams: null,
  setRemoteCameraStatus: () => null,
  remoteCameraStatus: null,
  setRemoteMicStatus: () => null,
  remoteMicStatus: null,
  setRemoteSpeakerStatus: () => null,
  remoteSpeakerStatus: null,
};

export const StreamContext = createContext<IStreamContext>(
  streamContextDefaults,
);
