import React, {ChangeEvent, HTMLInputTypeAttribute, useState} from 'react';

/**
 * Todo: Fix the onFocus OnBlur behavior it is not hightlighting the field
 * * Component and scss have been updated but not working
 */

interface FormInputProps {
  name: string;
  type: HTMLInputTypeAttribute;
  value: string;
  className?: string;
  style?: React.CSSProperties;
  placeholder?: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

export default function FormInput({
  name,
  type,
  value,
  onChange,
  className,
  style,
  placeholder,
}: FormInputProps) {
  const [focusedInput, setFocusedInput] = useState('');
  const handleFocus = (value: string) => {
    setFocusedInput(value);
  };

  return (
    <input
      name={name}
      type={type}
      className={`shadow-lg text-gray-300 font-[200] appearance-none bg-[#313139] rounded-lg w-full py-4 px-3 body-large-17-regular placeholder-17px leading-tight focus:outline-none focus:shadow-outline ${focusedInput === name ? 'focused' : 'border-[1px] border-transparent'} ${className}`}
      style={{ boxShadow: 'inset 2px 2px 6px rgba(0, 0, 0, 0.25)', ...style }}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      onFocus={() => handleFocus(name)}
      onBlur={() => handleFocus('')}
    />
  );
}
