import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {AuthContext, UserContext} from 'context/';
import { useApproveLogoutOthers } from 'hooks/useServerQueries';
import { FormWrapper } from '../../wrappers/FormWrapper';
import {
  PrimaryButton,
  Subtitle16,
} from '../../components-library/index.module';

        /**
        * TODO: After creating the secondaty button included into this modal
        */

export default function ChallengeModal() {
  const { mutateAsync: ApproveLogoutOthers } = useApproveLogoutOthers();
  const {
    sessionId,
    sessionIdFromParams,
  } = useContext(UserContext);
  const {
    setAuthToken,
    setAuthStep
  } = useContext(AuthContext)

  const navigate = useNavigate();

  const agree = async () => {
    const payload = {
      sessionId,
      solution: 'RP',
    };
    const res: any = await ApproveLogoutOthers(payload);

    if (res.challenge === "NONE") {
      const sessionId: string | null = localStorage.getItem('sessionId');
      setAuthToken(res.token);
      if (
        !sessionIdFromParams &&
        !sessionId
      ) navigate(`/checkYourEmail`);
      else navigate(`/checkSound`);
    }
  };

  const disagree = () => {
    setAuthStep('');
    navigate(`/auth`);
  };

  return (
    <FormWrapper>
      <div className="px-8 pt-4 pb-8">
        <p className="leading-8">
        It appears that you are currently signed in from another device.
        </p>
        <p className="leading-8"> Do you want to sign out from that device and continue here?</p>
        <div className="flex justify-end mt-4">
          <PrimaryButton
            text='Yes'
            styles="bg-gradient-to-t from-[#fb6520b8] to-[#fb6520db] hover:bg-[#FB6520] ease-in-out duration-200 cursor-pointer text-[17px] text-white py-2 px-6 shadow-primary-button rounded-[8px] mr-4"
            click={() => agree()}
          ></PrimaryButton>
          <button
            className="bg-[#313139] hover:bg-[#44444D] ease-in-out duration-200 cursor-pointer text-[17px] text-white py-2 px-6 shadow-cancel-button rounded-[8px] ml-4"
            onClick={() => disagree()}
          >
            No
          </button>
        </div>
      </div>
    </FormWrapper>
  );
}
