import { createContext } from 'react';
import { IUserContext } from 'types/context';

const userContextDefaults: IUserContext = {
  setSessionId: () => null,
  sessionId: '',
  setSessionIdFromParams: () => null,
  sessionIdFromParams: '',
  setNewAccount: () => null,
  isNewAccount: false,
  setUserId: () => null,
  userId: '',
};

export const UserContext = createContext<IUserContext>(userContextDefaults);
