import { createContext, useContext } from 'react';
import { ISocketContext } from 'types/context';

const socketContextDefaults: ISocketContext = {
  setSocketData: () => null,
  socketData: null,
  setCorrelationId: () => null,
  correlationId: null,
  setParticipants: () => null,
  participants: null,
};

export const SocketContext = createContext<ISocketContext>(
  socketContextDefaults,
);
