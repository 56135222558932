import React, { useState, ReactNode, useMemo } from 'react';
import { InterfaceContext } from 'context/';

type InterfaceContextProviderWrapperProps = { children: ReactNode };

function InterfaceContextProviderWrapper({
  children,
}: InterfaceContextProviderWrapperProps) {
  const [isListOfParticipants, toggleListOfParticipants] =
    useState<boolean>(false);
  const [joinModal, toggleJoinModal] = useState<boolean>(false)

  const values = useMemo(
    () => ({
      toggleListOfParticipants,
      isListOfParticipants,
      toggleJoinModal,
      joinModal
    }),
    [isListOfParticipants, joinModal],
  );

  return (
    <InterfaceContext.Provider value={values}>
      {children}
    </InterfaceContext.Provider>
  );
}

export default InterfaceContextProviderWrapper;
