import React, { useState } from 'react';
import {passwordConditionsArray} from "../../../constants";

export default function AuthErrorModal(props: Readonly<{error: any}>) {
  const errorsList = props.error.split(',')

  return (
    <div className=" absolute bg-[#202020] z-50 shadow-[#313139] rounded-lg border-[1px] border-[#48485C] p-[20px] pr-[40px]" style = {{transform: 'translate(525px, 10px'}}>
      <div className='flex items-center mb-2'>
        <div className={`${errorsList.includes('false') ? 'bg-[#EF494B]' : 'bg-[#40BD83]'} w-[10px] h-[10px] rounded-lg mr-[15px]`}></div>
        <p className={`${errorsList.includes('false') ? 'text-[#EF494B]' : 'text-[#40BD83]'}`}>Should contain at least:</p>
      </div>
      <div className='ml-[30px]'>
        {
          passwordConditionsArray.map((item, index) => (
            <div key={`${item}`}  className='flex items-center mb-2'>
              <div className={`${errorsList[index] === 'false' ? 'bg-[#EF494B]' : 'bg-[#40BD83]'} w-[10px] h-[10px] rounded-lg mr-[15px]`}></div>
              <p className={`${errorsList[index] === 'false' ? 'text-[#EF494B]' : 'text-[#40BD83]'}`}>{item}</p>
            </div>
          ))
        }
      </div>
      <div className='absolute left-[-18px] top-[28px] w-0 h-0 border-t-[12px] border-b-[12px] border-r-[19px] border-t-transparent border-b-transparent border-r-[#313139]'></div>
      <div className='absolute left-[-16px] top-[30px] w-0 h-0 border-t-[10px] border-b-[10px] border-r-[17px] border-t-transparent border-b-transparent border-r-[#202020]'></div>
    </div>
  );
}
