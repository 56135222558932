import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowDropdown } from '../../../components-library/index.module';
import { leavePayload } from 'constants/';
import { UserContext, SocketContext, InterfaceContext, StreamContext } from 'context/';
import styles from './styles.module.scss';
import {selectMicrophone, selectMuteMicrophone, selectSpeaker, selectMuteCamera, selectCamera} from 'utils/zegoCloud';
import {correlationId} from 'constants/index';
export default function StreamNavigation() {
  const { toggleListOfParticipants, isListOfParticipants } =
    useContext(InterfaceContext);
  const { roomId, localVideoRef, muteCamera, setMuteSpeakers, microphoneDevices, speakerDevices, cameraDevices, zegoCloudCred, muteSpeakers, audioElement, muteMicrophone, speakerDeviceSelected, setSpeakerDeviceSelected, setMuteMicrophone, setMuteCamera, setCameraDeviceSelected, localStream } =
    useContext(StreamContext);
  const { socketData } = useContext(SocketContext);
  const {userId} = useContext(UserContext);
  const navigate = useNavigate();

  const toggleParticipants = () => {
    toggleListOfParticipants(!isListOfParticipants);
  };

  const selectMicrophoneLoc = (deviceId: string) => {
    const { zg } = zegoCloudCred;
    selectMicrophone(deviceId, localStream, zg)
  };

  const selectSpeakerLoc = (deviceId: string) => {
    const { zg } = zegoCloudCred;
    setSpeakerDeviceSelected(deviceId)
    
    //selectSpeaker(deviceId, audioElement, zg)
  }

  const selectCameraLoc = (deviceId: string) => {
    const { zg } = zegoCloudCred;
    setCameraDeviceSelected(deviceId)
    selectCamera(deviceId, localStream, zg)
  }

  const selectMuteMicrophoneLoc = () => {
    const { zg } = zegoCloudCred;
    setMuteMicrophone(!muteMicrophone)
    selectMuteMicrophone(!muteMicrophone, zg)

    const muteMicPayload = {
      correlationId: correlationId,
      type: 'SELF_UPDATE_USER_AUDIO_IO',
      usersAudioIOState: {
        muted: !muteMicrophone
      },
    }

    socketData.send(JSON.stringify(muteMicPayload));
  };

  const leaveSession = () => {
    // zegoCloudCred.zg.stopPublishingStream(`stream-${userId}`)
    // zegoCloudCred.zg.logoutRoom(roomId);
    // zegoCloudCred.zg.destroyEngine();
    if (socketData) socketData.send(JSON.stringify(leavePayload));
    navigate('/logout');
  };

  const selectMuteCameraLoc = () => {
    const { zg } = zegoCloudCred;
    setMuteCamera(!muteCamera)
    selectMuteCamera(!muteCamera, localStream, zg)
  }

  const selectMuteSpeakersLoc = () => {
    setMuteSpeakers(!muteSpeakers)
    const muteSpkPayload = {
      correlationId: correlationId,
      type: 'SELF_UPDATE_USER_AUDIO_IO',
      usersAudioIOState: {
        userIds: [userId],
        deafened: !muteSpeakers
      },
    }

    socketData.send(JSON.stringify(muteSpkPayload));
  }

  const handleSpacebar = () => {
    console.log('Spacebar pressed');
    const { zg } = zegoCloudCred;
    setMuteMicrophone(!muteMicrophone)
    selectMuteMicrophone(!muteMicrophone, zg)
    
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      // Check if the pressed key is the spacebar
      if (event.code === 'Space') {
        handleSpacebar();
      }
    };

    // Add the event listener
    window.addEventListener('keydown', handleKeyDown);

    // Clean up the event listener
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [muteMicrophone])

  return (
    <div className="flex flex-row justify-center mt-8 toolbar relative">
      <div className="flex flex-row">
        <div
          id="fullBtn"
          className="flex flex-row mr-4 divide-x divide-slate-400/25"
        >
          <button
              className="flex items-center bg-[#313139] px-4 py-2 h-full rounded-l-lg hover:cursor-pointer"
              onClick={selectMuteCameraLoc}
          >
            {!muteCamera ?
                <img
                    src="/icons/ic_CameraLine_XS_default.svg"
                    alt="fullscreen"
                    width={20}
                    height={20}
                /> :
                <img
                    src="/icons/ic_CameraLine_XS_pressed.svg"
                    alt="fullscreen"
                    width={20}
                    height={20}
                    
                />
            }
          </button>
          <div className="flex align-center bg-[#313139] px-2 py-2 h-full rounded-r-lg">
            <ArrowDropdown
                dataDropdown={cameraDevices}
                handle={selectCameraLoc}
            />
          </div>
        </div>
        <div
          id="fullBtn"
          className="flex flex-row mr-4 divide-x divide-slate-400/25"
        >
          <button
            className="flex items-center bg-[#313139] px-4 py-2 h-full rounded-l-lg hover:cursor-pointer"
            onClick={selectMuteMicrophoneLoc}
          >
            {muteMicrophone ?
              <img
                src="/icons/micOff.svg"
                alt="fullscreen"
                width={20}
                height={20}
              /> :
              <img
                src="/icons/micOn.svg"
                alt="fullscreen"
                width={20}
                height={20}
              />
            }
          </button>
          <div className="flex align-center bg-[#313139] px-2 py-2 h-full rounded-r-lg">
            <ArrowDropdown
              dataDropdown={microphoneDevices}
              handle={selectMicrophoneLoc}
            />
          </div>
        </div>
        <div
          id="fullBtn"
          className="flex flex-row mr-4 divide-x divide-slate-400/25"
        >
           <button
            className="flex items-center bg-[#313139] px-4 py-2 h-full rounded-l-lg hover:cursor-pointer"
            onClick={selectMuteSpeakersLoc}
          >
            {!muteSpeakers ? <img
              src="/icons/headphones.svg"
              alt="fullscreen"
              width={20}
              height={20}
            />:
            <img
            src="/icons/muteSpeakersIcon.svg"
            alt="fullscreen"
            width={20}
            height={20}
        
            
            />}
          </button>
          <div className="flex align-center bg-[#313139] px-2 py-2 h-full rounded-r-lg">
            <ArrowDropdown
              dataDropdown={speakerDevices}
              handle={selectSpeakerLoc}
            />
          </div>
        </div>
        {/* <button id="muteBtn" className='mr-4'>
                    <img src="/icons/headphones.svg" alt="fullscreen" width={20} height={20} />
                </button> */}
        <div
          id="muteBtn"
          className={`px-2 py-2 rounded-md border-[#F44336] border ${styles.leaveBtnBg} hover:cursor-pointer`}
        >
          <button className="text-[#F44336]" onClick={() => leaveSession()}>
            Leave Session
          </button>
        </div>
      </div>
      <button
        className="absolute flex align-center h-full right-0 bg-[#313139] rounded-full px-2 py-3 hover:cursor-pointer"
        onClick={() => toggleParticipants()}
      >
        <img
          src="/icons/participants1.svg"
          alt="participants"
          width={24}
          height={17}
        />
      </button>
    </div>
  );
}
