import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {UserContext, StreamContext, InterfaceContext, SocketContext, AuthContext} from 'context';
import {
  Header,
  SessionDetails,
  SessionAudioSettings,
} from 'ui/components/index.module';
import {
  useChatConnection,
  useConnectRoom,

  useGetSessionData,
  useLogout,
} from 'hooks/useServerQueries';
import { zegoCloudConnection, checkSystemRequirements } from 'utils/zegoCloud';
import JoinSessionModal from 'ui/modals/JoinSessionModal';
import { bool } from 'yup';

export default function CheckSound() {

  const [hasID, setHasId] = React.useState(true);
  const {
    setZegoCloudCred,
    setSessionData,
    setRoomData,
    setRoomId, 
    sessionData
  } = useContext(StreamContext);
  const { sessionIdFromParams, setSessionIdFromParams, setSessionId } = useContext(UserContext);
  const {
    setAuthStep,
    authToken,
    setAuthToken,
  } = useContext(AuthContext);
  const { joinModal } = useContext(InterfaceContext)
  const { mutateAsync: connectRoom } = useConnectRoom(sessionIdFromParams);

  const { mutateAsync: chatConnection } = useChatConnection(sessionIdFromParams);
  const { mutateAsync: Logout } = useLogout();
  const { mutateAsync: getSessionData } =
    useGetSessionData(sessionIdFromParams);
  const navigate = useNavigate()

  const getSession = async () => {
    const res = await getSessionData(sessionIdFromParams);
    if (res.status === 200) {
      setSessionData(res);
      return res;
    } else {
      await Logout({ AccessToken: `${authToken}`, solution: 'RP' });
      console.log("#REMOVE_SESSIONID_1")
      setAuthStep('');
      setAuthToken(null);
      setSessionId(null);
      setSessionIdFromParams(undefined);
      
      localStorage.removeItem('token');
      localStorage.removeItem('sessionId');
      localStorage.removeItem('userEmail');
      navigate('/auth')
    }
  };

  const getRoomData = async () => {
    const chatData = await chatConnection(sessionIdFromParams);
    const _roomData = await connectRoom(sessionIdFromParams);
    //const streamData = await connectStream(sessionIdFromParams);
    //console.log('streamData', streamData)
    console.log("roomData", _roomData)
    console.log("chatData", chatData)
    let data = {..._roomData, zegoCloud: {...chatData.zegoCloudConfig}}
    
    setRoomData(data);
  
    return data;
  };

  useEffect(() => {
    if (!sessionIdFromParams) {
      const sessionId = localStorage.getItem('sessionId');
      if (sessionId) {
        setSessionIdFromParams(sessionId);
      }
    }
  }, []);
  const fetchData = async () => {
    if (sessionIdFromParams) {
      const sessionData = await getSession();
     
      const _roomData = await getRoomData();
      setRoomId(_roomData.roomId);
      console.log("roomData", _roomData);
      console.log("sessionData", sessionData);
      if (sessionData && _roomData && sessionData?.state !== 'READY_TO_START') {
        const zegoCloudCredData = await zegoCloudConnection(_roomData);
        await checkSystemRequirements(zegoCloudCredData.zg).then((res) => {
          if (res) {
            console.log("systemRequirements", res);
            setHasId(true);
          }
        });
        setZegoCloudCred(zegoCloudCredData);
      }
    }
    else {
      setHasId(false);
    }

  };
  useEffect(() => {   
    fetchData();
  }, [sessionIdFromParams, hasID]);
  

  useEffect(()=>{
    if(sessionData?.state === 'READY_TO_START'){
      setTimeout(()=> {fetchData()}, 5000)
    }
  },[sessionData])

  return (
    <div className="h-screen">
      <Header />
      <div className="flex flex-col items-center justify-center h-[calc(100%-70px)]">
        {!joinModal ?
          <div className="2xl:w-[1650px] xl:w-[1380px] lg:w-[980px] md:w-[700px] flex xl:flex-row flex-wrap justify-around w-full items-center">
            <SessionDetails />
            <SessionAudioSettings />
          </div>
          : <JoinSessionModal />
        }
      </div>
      

    </div>
  );
}
