import React, {useEffect, useRef, useState} from 'react';
import styles from './styles.module.scss';


interface IDeviceConfig {
  deviceID: string,
  deviceName: string
}

export default function Dropdown(props: Readonly<{dataDropdown: Array<IDeviceConfig>, defaultText: string, handle: Function, classesBlock?: string}>) {
  const { dataDropdown, defaultText, handle, classesBlock } = props;
  const [stateDrdopdown, setStateDrdopdown] = useState(false);
  const [selectedDeviceName, setSelectedDeviceName] = useState<string>('');
  const dropdownRef = useRef<any>(null);

  const toggleDropdown = () => {
    setStateDrdopdown(!stateDrdopdown);
  };

  const closeDropdown = () => {
    setStateDrdopdown(false);
  };

  const handleDropdown = (item: IDeviceConfig) => {
    setSelectedDeviceName(item.deviceName);
    handle(item.deviceID);
    closeDropdown();
  };

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      const current = dropdownRef.current
      if (current && !current.contains(event.target)) {
        closeDropdown();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className={`relative shadow-dropdown-container rounded ${classesBlock}`} ref={dropdownRef}>
      <button
        onClick={toggleDropdown}
        className="flex flex-row items-center justify-between px-4 py-2 bg-[#313139] w-full rounded text-left hover:cursor-pointer"
      >
        <p className={`w-[120px] body-rf-12 ${styles.threeDots}`}>
          {selectedDeviceName !== '' ? selectedDeviceName : defaultText}
        </p>
        <img
          src="/icons/dropdownArrow.svg"
          alt="drodown arrow"
          width={14}
          height={14}
          className={`${stateDrdopdown && 'rotate-180'}`}
        />
      </button>
      {stateDrdopdown ? (
        <ul className="absolute bg-[#313139] w-full rounded z-50 max-h-[300px] md:max-h-[250px] sm:max-h-[180px] overflow-y-scroll shadow-dropdown-container top-8">
          {dataDropdown?.map((item: IDeviceConfig) => (
            <li
              className="px-4 py-2 hover:bg-[#313180]"
              key={item.deviceID}
              onClick={() => handleDropdown(item)}
            >
              <button className="text-left body-rf-12" onClick={() => handleDropdown(item)}>
                {item.deviceName}
              </button>
            </li>
          ))}
        </ul>
      ) : null}
    </div>
  );
}
