import React, {useState, ReactNode, useMemo, useEffect} from 'react';
import { AuthContext } from 'context/';
import { useRefreshToken } from "../hooks/useServerQueries";
import {axiosConfig} from "../utils/axios";

type AuthContextProviderWrapperProps = { children: ReactNode };

function AuthContextProviderWrapper({
                                      children,
                                    }: AuthContextProviderWrapperProps) {
  const [authToken, setAuthToken_] = useState<string | null>(null);
  const [authStep, setAuthStep] = useState<string | string[] | undefined>('');
  const { mutateAsync: refreshToken } = useRefreshToken();

  const setAuthToken = (newToken: string) => {
    setAuthToken_(newToken);
    localStorage.setItem('token', newToken);
  };

  const updateToken = async () => {
    const authToken = localStorage.getItem('token')
    if (authToken) {
      const data: any = await refreshToken({token: authToken, solution: "RR"})
      if (data) {
        setAuthToken(data.token)
      }
    }
  };

  useEffect(() => {
    updateToken();

    const intervalId = setInterval(updateToken, 3600000);

    return () => {
      clearInterval(intervalId);
    }
  }, [])

  // set token after reload or hard reload of the page
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!authToken && token) {
      setAuthToken(token)
    }
  }, [authToken]);

  useEffect(() => {
    //   axiosConfig.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
    //
    //   const token = authToken || localStorage.getItem('token');
    //   if (token) {
    //     axiosConfig.defaults.headers.common = { Authorization: `Bearer ${authToken}` };
    //   }
    const token = authToken || localStorage.getItem('token');
    if (token) {
      axiosConfig.defaults.headers.common = { Authorization: `Bearer ${authToken}` };
      localStorage.setItem("token", token);
    } else {
      delete axiosConfig.defaults.headers.common["Authorization"];
      localStorage.removeItem("token");
    }
  }, [authToken]);

  const values = useMemo(
    () => ({
      setAuthToken,
      authToken,
      setAuthStep,
      authStep,
    }),
    [
      authToken,
      authStep,
    ],
  );

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
}

export default AuthContextProviderWrapper;
