import React, { useContext } from 'react';
import {
  LoginModal,
  ChallengeModal,
  RegisterModal,
  ForgetPasswordModal,
} from 'ui/modals/index.module';
import {AuthContext} from 'context/';
import ResendPasswordModal from 'ui/modals/ResendPasswordModal';

export default function Login() {
  const { authStep } = useContext(AuthContext);

  const typeModal = () => {
    switch (authStep) {
      case 'challenge': return <ChallengeModal />;
      case 'new': return <RegisterModal />
      case 'forget': return <ForgetPasswordModal />
      case 'resend': return <ResendPasswordModal />
      default: return <LoginModal />
    }
  };

  return <div className="flex h-screen styles.background">{typeModal()}</div>;
}
