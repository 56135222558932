import React, { useContext, useEffect, useState } from 'react';
import { StreamContext } from 'context/';
import { converStreamStateToValue } from 'utils/convertTypeToValue';
import { getDate } from 'utils/parseDate';
import { IParticipant } from 'types';

export default function SessionDetails() {
  const { sessionData, setOrganizer, organizer } = useContext(StreamContext);
  const [timeSession, setTimeSession] = useState<string>('');

  const getOrganizerData = () => {
    sessionData?.participants.filter(
      (item: IParticipant) =>
        item.userId === sessionData?.organizerId && setOrganizer(item),
    );
  };

  useEffect(() => {
    if (sessionData) {
      getOrganizerData();
      setTimeSession(getDate(sessionData.startDateTime, sessionData.duration));
    }
  }, [sessionData]);

  return (
    <>
      {sessionData && (
        <div className="flex flex-col items-center xl:mb-0 xl:w-[500px]  lg:mb-6 lg:w-[680px] md:mb-6 md:w-[680px]">
          <div className="mb-4 rounded-lg overflow-hidden">
            <img
              src="/logo/Loading-FullLogo.gif"
              alt="logo"
              width={80}
              height={80}
            />
          </div>
          {sessionData?.state !== 'CLOSED' ? (
            <div className="mb-8">
              <p className="title-36-reg text-center mb-4">
                {`Your session ${(sessionData?.state === 'READY_TO_START') ? 'is ' : 'has '}`}
                <label className="lowercase">{converStreamStateToValue(sessionData?.state)}</label>.
              </p>
              <p className="subtitle-20-reg text-center">
                {(sessionData?.state === 'READY_TO_START') ? 'Please wait while organizer will start the session'
                : 'Ask to join the session after checking your settings.'}
              </p>
            </div>
          ) : (
            <div className="mb-8">
              <p className="text-4xl text-center mb-4">
                Your session was{' '}
                <label className="lowercase">{sessionData?.state}</label>.
              </p>
            </div>
          )}
          <div className="bg-[#202020] border border-white rounded w-full px-3 py-6">
            <p className="title-large-28-regular text-center mb-2 capitalize">
              {sessionData?.name}
            </p>
            <p className="body-large-17-regular text-center mb-4 capitalize">
              Organizer - {organizer?.firstName} {organizer?.lastName}
            </p>
            <p className="text-center">{timeSession}</p>
          </div>
        </div>
      )}
    </>
  );
}
