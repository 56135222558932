import { createContext } from 'react';
import { IInterfaceContext } from 'types/context';

const interfaceContextDefaults: IInterfaceContext = {
  toggleListOfParticipants: () => null,
  isListOfParticipants: false,
  toggleJoinModal: () => null,
  joinModal: false,
};

export const InterfaceContext = createContext<IInterfaceContext>(
  interfaceContextDefaults,
);
