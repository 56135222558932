import React from 'react';

export default function CustomCheckbox(props: Readonly<{text: string, checked: boolean, handler: Function, classesBlock?: string, id: string }>) {
  const { text, checked, handler, classesBlock, id } = props;

  return (
      <div className={`flex flex-row justify-center hover:cursor-pointer border border-[var(--Mako)] rounded px-4 py-2 ${classesBlock}`}>
          <input
            type="checkbox"
            className="mr-2"
            id={id ? id : "custom-checkbox"}
            checked={checked}
            onChange={(e) => handler(e)}
          />
          <label htmlFor={id ? id : "custom-checkbox"} className="footnote-10-rf">{text}</label>
      </div>
  );
}
