import React, {useState, ReactNode, useMemo, useEffect, useContext} from 'react';
import {AuthContext, InterfaceContext, SocketContext, StreamContext, UserContext} from 'context/';
import { IParticipantSocket } from 'types';
import {websocketOnClose} from "../utils/websocket";
import {useConnectRoom} from "../hooks/useServerQueries";

type SocketContextProviderWrapperProps = { children: ReactNode };

function SocketContextProviderWrapper({
  children,
}: SocketContextProviderWrapperProps) {
    const {
        sessionIdFromParams,
    } = useContext(UserContext);
    const {
      authToken
    } = useContext(AuthContext)
    const {
        sessionData,
        setRoomData
    } = useContext(StreamContext);
    const { mutateAsync: connectRoom } = useConnectRoom(sessionIdFromParams);
  const [socketData, setSocketData] = useState<any>(null);
  const [correlationId, setCorrelationId] = useState<string | null>(null);
  const [participants, setParticipants] = useState<Array<IParticipantSocket> | null>(null);

  const values = useMemo(
    () => ({
      setSocketData,
      socketData,
      setCorrelationId,
      correlationId,
      setParticipants,
      participants
    }),
    [socketData, correlationId, participants],
  );

    const getRoomData = async () => {
        const roomData = await connectRoom(sessionIdFromParams);
        await setRoomData(roomData);
        return roomData;
    };

    useEffect(() => {
        const updateSocketConnection = async () => {
            if (sessionData && sessionData?.state !== 'STARTED') {
                const roomData = await getRoomData()
                if (authToken && roomData) {
                    websocketOnClose(socketData, authToken, roomData, setSocketData)
                    console.log('reconnected')
                }
                // zegoCloudCred.zg.destroyEngine();
                // const zegoCloudCredData: any = await zegoCloudConnection(roomData)
                // await checkSystemRequirements(zegoCloudCredData.zg)
                // await setZegoCloudCred(zegoCloudCredData)
            }
        };

        const intervalId = setInterval(updateSocketConnection, 3000);//6600000

        return () => {
            clearInterval(intervalId);
        }
    }, []);

  return (
    <SocketContext.Provider value={values}>{children}</SocketContext.Provider>
  );
}

export default SocketContextProviderWrapper;
