import React, { useContext, useEffect, useRef, useState } from 'react';
import styles from './styles.module.scss';
import { UserContext, StreamContext } from 'context/';
import { useGetSessions } from 'hooks/useServerQueries';
import StreamElement from '../StreamElement';
import StreamHeader from '../StreamHeader';
import { convertStreamTypeToValue } from 'utils/convertTypeToValue';
import { ISessionContent } from 'types';
import { SocketContext } from 'context/SocketContext';
export default function StreamWindow() {
  const { mutateAsync: GetSessions } = useGetSessions();
  const { sessionIdFromParams } = useContext(UserContext);
  const { streamState } = useContext(StreamContext);
  const [sessionData, setSessionData] = useState<ISessionContent>();
  const [isFullscreen, setIsFullscreen] = useState<boolean>(false);
  const [showMainStream, setShowMainStream] = useState<any>();  
  const [streamStateLoc, setStreamStateLoc] = useState<string>('');
  const fullscreenObj = useRef<any>();
  const { socketData } = useContext(SocketContext);
  const getListOfSessions = async () => {
    const data = await GetSessions();
    if (data) {
      data.content.filter(
        (item: ISessionContent) => item?.id === sessionIdFromParams && setSessionData(item),
      );
    }
  };

  const updateStreamStateLoc = () => {
    const streamStateFunc = convertStreamTypeToValue(streamState);
    setStreamStateLoc(streamStateFunc);
  };

  const fullscreen = () => {
    setIsFullscreen(!isFullscreen);
    if (isFullscreen === true) fullscreenObj.current.requestFullscreen();
    else if(isFullscreen === false) if (document.fullscreenElement) {
      setTimeout(() => document.exitFullscreen(), 100);
    }
  };

  useEffect(() => {
    getListOfSessions();
    updateStreamStateLoc();
  }, []);

  useEffect(() => {
    if (socketData) {
      socketData.onmessage = function (event: any) {
        const eventData = JSON.parse(event.data);

        switch (eventData.type) {
          case 'STREAM_CONTROL_SETTING_CHANGED': {
              if (eventData.streamControl.action === "STOP"){
                  setShowMainStream(false);
              }
              if (eventData.streamControl.action === "START"){
                  setShowMainStream(true);
              }
            
            break;
          }
          case 'USER_LEFT_ROOM': {
            
            break;
          }
          case 'USER_DISCONNECTED_ROOM': {
    
            break;
          }
        }
      }
    }
  },[socketData]);

  return (
    <div
      className="rounded-lg border-[#44444D] border h-full"
      ref={fullscreenObj}
    >
      {sessionData && (
        <>
          <div className={`px-2 py-2 rounded-t-lg ${styles.titleBg}`}>
            <p className="text-[#8A8A96]">Video</p>
          </div>
          <StreamHeader
            streamName={sessionData.name}
            streamState={streamStateLoc}
          />
          <StreamElement />
          <div className="flex flex-row justify-end px-4 py-4 bg-[#313139] rounded-b-lg">
            {/* <p>00:00:03:01</p> */}
            <button className="flex flex-row hover:cursor-pointer" onClick={fullscreen}>
              <img
                src="/icons/fullScreen.svg"
                alt="fullscreen"
                width={20}
                height={20}
                // className="pr-2"
                className='h-[15px] w-[15px]'
              />
            </button>
            <img src="/icons/info.svg" alt="info" width={20} height={20} className='hidden'/>
          </div>
        </>
      )}
    </div>
  );
}
