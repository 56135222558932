import React, { ReactNode } from 'react';
import StreamContextProviderWrapper from './StreamContext.provider';
import ExtLibProviderWrapper from './ExtLib.provider';
import UserContextProviderWrapper from './UserContext.provider';
import SocketContextProviderWrapper from './SocketContext.provider';
import InterfaceContextProviderWrapper from './InterfaceContext.provider';
import AuthContextProviderWrapper from "./AuthContext.provider";

type ProvidersWrapperProps = { children: ReactNode };

function Providers({ children }: ProvidersWrapperProps) {
  return (
      <ExtLibProviderWrapper>
        <AuthContextProviderWrapper>
          <InterfaceContextProviderWrapper>
            <UserContextProviderWrapper>
              <StreamContextProviderWrapper>
                <SocketContextProviderWrapper>
                  {children}
                </SocketContextProviderWrapper>
              </StreamContextProviderWrapper>
            </UserContextProviderWrapper>
          </InterfaceContextProviderWrapper>
        </AuthContextProviderWrapper>
      </ExtLibProviderWrapper>
  );
}

export default Providers;
