import React, { useEffect, useRef, useState } from 'react';
import { StreamContext } from 'context';

const RemoteVideoComponent = ({ stream, zegoCloudCred, participantInfo }: { stream: any, zegoCloudCred: any, participantInfo: any }) => {
    const videoRef = useRef<any>(null);
    const { muteSpeakers, remoteCameraStatus, remoteMicStatus } = React.useContext(StreamContext);

    const [cameraStatus, setCameraStatus] = useState<boolean>(remoteCameraStatus[stream.streamID]);
    const [micStatus, setMicStatus] = useState<boolean>(remoteMicStatus[stream.streamID]);

    useEffect(() => {
        let remoteStream: any;

        const startPlaying = async () => {
            try {
                remoteStream = await zegoCloudCred.zg.startPlayingStream(stream.streamID, {
                    video: true,
                    audio: true,
                });
                if (videoRef.current) {
                    videoRef.current.srcObject = remoteStream;
                }
            } catch (e: any) {
                console.log('roomStreamUpdate error ', e);
            }
        };

        const stopPlaying = async () => {
            try {
                await zegoCloudCred.zg.stopPlayingStream(stream.streamID);
                if (videoRef.current) {
                    videoRef.current.srcObject = null;
                }
            } catch (e: any) {
                console.log('stopPlayingStream error ', e);
            }
        };

        stopPlaying().then(startPlaying);

        return () => {
            stopPlaying();
        };
    }, [stream.streamID, zegoCloudCred.zg]);

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.muted = muteSpeakers;
        }
    }, [muteSpeakers]);

    useEffect(() => {
        setCameraStatus(remoteCameraStatus[stream.streamID]);
    }, [remoteCameraStatus, stream.streamID]);

    useEffect(() => {
        setMicStatus(remoteMicStatus[stream.streamID]);
    }, [remoteMicStatus, stream.streamID]);

    return (
        <div className="video-container" style={{ position: 'relative' }}>
            <video
                style={{ borderRadius: '5px', height: '100%', width: '100%', objectFit: 'cover'}}
                ref={videoRef}
                id={stream.streamID}
                hidden={!cameraStatus}
                autoPlay
                playsInline
            />
            {!cameraStatus && (
                <div style={{
                    position: 'relative',
                    maxWidth: '100%',
                    height: '150px',
                    backgroundColor: 'black',
                    borderRadius: '5px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: 'white',
                    fontSize: '16px'
                }}>
                    <img src="/icons/ic_CameraLine_XS_pressed.svg" alt={''} style={{ border: '2px solid #FB6520', borderRadius: '100px', padding: '10px', width: '50px', height: '50px' }} />
                </div>
            )}
            <div style={{
                transform: 'translate(-5px, 5px)',
                textShadow: '2px 2px 2px rgba(0, 0, 0, 0.9)',
                position: 'absolute',
                bottom: '5px',
                left: '10px',
                color: 'white'
            }}
                className="participant-name">
                {participantInfo?.name}
            </div>

            <div style={{
                backgroundColor: '#3f3f3f',
                borderRadius: '100px',
                border: `2px solid ${micStatus ? '#2DBF81' : '#FB6520'}`,
                padding: '2px',
                position: 'absolute',
                bottom: '5px',
                right: '5px',
                color: 'white'
            }}
                className="participant-name">
                {micStatus ? <img alt={''} style={{ width: '25px', height: '25px' }} src={'/icons/micOn.svg'} /> : <img alt={''} style={{ padding: '1px', width: '25px', height: '25px' }} src={'/icons/micOff.svg'} />}
            </div>
        </div>
    );
};

export default RemoteVideoComponent;
