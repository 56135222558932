import { dayNames, monthNames } from 'constants/';

export const getDate = (startDateTime: string, duration: number ): string => {
    console.log('startDateTime', startDateTime);    
    const startSessionDate = new Date(startDateTime);
    console.log('startSessionDate', startSessionDate);
    // incoming date format is UTC time, need to convert to local time
    startSessionDate.setMinutes(startSessionDate.getMinutes() - startSessionDate.getTimezoneOffset());
    const startSessionToLocale = startSessionDate.toLocaleTimeString(
        navigator.language,
        { hour: '2-digit', minute: '2-digit' },
    );
    console.log('startSessionToLocale', startSessionToLocale);
    const day = startSessionDate.getDay();
    const month = startSessionDate.getMonth();
    console.log('day', day);
    console.log('month', month);
    const endSessionCalculate =
        startSessionDate.getTime() + duration * 60000;
    const endSessionDate = new Date(endSessionCalculate);
    const endSessionToLocale = endSessionDate.toLocaleTimeString(
        navigator.language,
        { hour: '2-digit', minute: '2-digit' },
    );
    return `${dayNames[day]}, ${startSessionDate.toString().split(' ')[2]}  ${monthNames[month]}, ${startSessionToLocale} : ${endSessionToLocale}`
};