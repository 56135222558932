import Participants from 'ui/components/Participants';
import { useNavigate } from 'react-router-dom';
import React, { FC, useContext, useState, useEffect, useRef } from 'react';
import { StreamContext, SocketContext, UserContext, InterfaceContext } from 'context';
import { useConnectStream } from 'hooks/useServerQueries';
import RemoteVideoComponent from './remoteVideoComponent';
import { IZegoCloudStreamObj, IParticipant } from 'types';
import { get } from 'http';
import { useGetCurrentUser } from 'hooks/useServerQueries';
import { selectMicrophone, selectMuteMicrophone, selectMuteCamera, selectSpeaker, selectCamera} from 'utils/zegoCloud';
import { IParticipantSocket } from 'types/participants';
import { log } from 'console';
import { useChatConnection } from 'hooks/useServerQueries';
interface ZegoParentProps {
  // Define your prop types here
}

function ZegoParent(props) {
  //const localVideoRef = useRef(null);
  const {
    localVideoRef,
    localStream,
    setLocalStream,
    sessionData,
    zegoCloudCred,
    roomId,
    roomData,
    setRoomData,
    speakerDeviceSelected,
    microphoneDeviceSelected,
    cameraDeviceSelected,
    muteMicrophone,
    muteCamera,
    setAudioElement,
    dolbyStreamingConfig,
    setMuteMicrophone,
    muteSpeakers,
    setMuteSpeakers,
    setRemoteCameraStatus,
    remoteCameraStatus,
    setRemoteMicStatus,
    remoteMicStatus,
    setRemoteSpeakerStatus,
    remoteSpeakerStatus,
    setDolbyStreamingConfig } = useContext(StreamContext);
  const email = localStorage.getItem('userEmail');

  const [isLocalStreamInitialized, setIsLocalStreamInitialized] = useState(false);
  //const [currentUserData, setCurrentUserData] = useState(null);
  const [doCapture, setDoCapture] = useState(true);
  const { participants, socketData, setParticipants } = useContext(SocketContext);
  const navigate = useNavigate();
  const [curUserData, setCurUserData] = useState<any>(null);
  const { sessionIdFromParams, userId, setUserId } = useContext(UserContext);
  const { mutateAsync: connectStream } = useConnectStream(sessionIdFromParams);
  const { isListOfParticipants } = useContext(InterfaceContext);
  const { mutateAsync: CurrentUser } = useGetCurrentUser()
  const [loggedInZg, setLoggedInZg] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [remoteStreams, setRemoteStreams] = useState<any>([]);
  const parent_remote_audioRef = useRef(null);

  const { mutateAsync: chatConnection } = useChatConnection(sessionIdFromParams);

  async function handleTokenWillExpire() {
    console.log(`Token for room ${roomId} is about to expire. Renewing now...`);
       // Get a new token
    const chatData = await chatConnection(sessionIdFromParams);
    let data = {...roomData, zegoCloud: {...chatData.zegoCloudConfig}}
    setRoomData(data);
 
  }

  const zegoCloudEvents = (zg: any) => {

    zg.on('tokenWillExpire', handleTokenWillExpire)


    zg.on('roomStateUpdate', (roomID: any,
      state: any,
      errorCode: any,
      extendedData: any) => {
      if (state == 'DISCONNECTED') {
        console.log('roomStateUpdate DISCONNECTED')
      }

      if (state == 'CONNECTING') {
        console.log('roomStateUpdate CONNECTING')
      }

      if (state == 'CONNECTED') {
        console.log('roomStateUpdate CONNECTED')
      }
    })

    zg.on('remoteCameraStatusUpdate', (streamID: any, status: any) => {
      if (remoteStreams.some((stream:any) => stream.streamID === streamID)) {
        setRemoteCameraStatus((prevStatus:any ) => ({
          ...prevStatus,
          [streamID]: (status !== 'MUTE'),
        }));
      }
    });
    zg.on('remoteMicStatusUpdate', (streamID: any, status: any) => {
      if (remoteStreams.some((stream:any) => stream.streamID === streamID)) {
        setRemoteMicStatus((prevStatus:any ) => ({
          ...prevStatus,
          [streamID]: (status !== 'MUTE'),
        }));
      }
    });
    
      


    
    


    zg.on('roomStreamUpdate', async (roomID: any, updateType: any, streamList: any) => {
      let _outStreams: any[] = []
      if (updateType === 'ADD') {
        console.log("STREAM_LIST", streamList)
        let outStreams = remoteStreams;
        streamList.forEach((stream: any) => {
          // Create a new stream object and add it to the state
          console.log("NEW_REMOTE_STREAM", stream)
          console.log("EXISTING_STREAMS", remoteStreams)


          if (remoteStreams.map((stream: any) => stream.streamID).includes(stream.streamID)) {
            console.log("STREAM_ALREADY_EXISTS")
          }
          else {
            outStreams.push(stream)
          }

          console.log("REMOTE_STREAMS", outStreams)

        });
        _outStreams = outStreams
      }
      else if (updateType === 'DELETE') {
        // Create a set of stream IDs to be removed
        const streamIDsToRemove = new Set(streamList.map((stream: any) => stream.streamID));
        console.log("STREAMS_TO_REMOVE", streamIDsToRemove)
        // Use functional state update to filter out streams
        let val = (prevStreams: any) =>
          prevStreams.filter((stream: any) => !streamIDsToRemove.has(stream.streamID));
        _outStreams = val(remoteStreams)
        console.log("REMOTE_STREAMS_OUT", _outStreams)


      }

      const uniqueRemoteStreams = Array.from(new Set(_outStreams.map((stream: any) => stream.streamID)))
        .map((streamID: any) => _outStreams.find((stream: any) => stream.streamID === streamID));
      setRemoteStreams(uniqueRemoteStreams);
      console.log("UNIQUE_REMOTE_STREAMS", uniqueRemoteStreams)
    });



    /*zg.on(
      'roomStreamUpdate',
      async (
        roomID: any,
        updateType: any,
        streamList: any,
        extendedData: any
      ) => {

        console.log("$$999", participants)
        if (updateType == 'ADD') {
          await streamList.map((stream: any) => {
            zg.startPlayingStream(stream.streamID, {
              video: true,
              audio: true,
            }).then((remoteStream: any) => {

              const remoteUserVideo = document.createElement('video');
              const text = document.createElement('div');
              const remoteUserVideoCard = document.createElement('div');
              const mutedIcon = document.createElement('img');
              const unmutedIcon = document.createElement('img');
              const userInfoTray = document.createElement('div');


              function getParticipantName(participants: any, stream: any) {
                console.log("Participants", participants)
                const participant = participants.find((item: any) => stream.streamID.includes(item.userId));
                return participant ? `${(participant.firstName != '') ? participant.firstName : 'Guest'} ${participant.lastName}` : '';
              }

              // Create a text element
              text.style.textShadow = '2px 2px 2px rgba(0, 0, 0, 0.9)';
              mutedIcon.style.boxShadow = '2px 2px 2px rgba(0, 0, 0, 0.9)';
              unmutedIcon.style.boxShadow = '2px 2px 2px rgba(0, 0, 0, 0.9)';


              text.innerText = getParticipantName(participants, stream);
              text.style.position = 'relative';
              text.style.color = 'white';
              text.style.fontSize = '16px';

              mutedIcon.setAttribute('src', '/icons/micOff.svg');
              unmutedIcon.setAttribute('src', '/icons/micOn.svg');
              mutedIcon.style.backgroundColor = "rgba(0, 0, 0, 0.5)";
              mutedIcon.style.borderRadius = "100px"
              unmutedIcon.style.borderRadius = "100px"

              unmutedIcon.style.backgroundColor = "rgba(0, 0, 0, 0.5)";

              userInfoTray.style.position = 'absolute';
              userInfoTray.style.bottom = '5px';
              userInfoTray.style.left = '10px';
              userInfoTray.style.width = '50%';
              userInfoTray.style.height = 'auto';
              userInfoTray.style.display = 'flex';
              userInfoTray.style.flexDirection = 'row';
              userInfoTray.style.justifyContent = 'space-between';
              userInfoTray.appendChild(text);
              userInfoTray.appendChild(unmutedIcon);
              remoteUserVideo.hidden = false;
              // Append the text element to the video element


              // Set the styles and attributes for the video element
             
              remoteUserVideo.setAttribute('id', stream.streamID);
              remoteUserVideo.srcObject = remoteStream
              remoteUserVideo.autoplay = true
              remoteUserVideo.hidden = false;
              remoteUserVideo.style.display = 'flex'


              remoteUserVideo.style.height = '100%'
              remoteUserVideo.style.objectFit = 'cover'
              remoteUserVideo.style.borderRadius = '15px'



              remoteUserVideoCard.style.position = 'relative';
              remoteUserVideoCard.style.width = 'stretch';
              remoteUserVideoCard.style.height = '200px';


              remoteUserVideoCard.appendChild(remoteUserVideo);
              remoteUserVideoCard.appendChild(userInfoTray);
              // Append the text element to the video element


              remoteUsersBlock?.append(remoteUserVideoCard)

              remoteUserVideoCard.setAttribute('id', stream.streamID)

              remoteStream.muted = false
              ///insert handler for onRemoteCameraStatusUpdate
            }).catch((e: any) => { console.log('roomStreamUpdate error ', e) })
          })
        } else if (updateType == "DELETE" && zg && streamList[0].streamID) {
          streamList.map((stream: any) => {
            const currentVideo = document.getElementById(stream.streamID)
            currentVideo?.remove()
            zg.stopPlayingStream(stream.streamID)
          })
        }
      }) */

    zg.on(
      'roomUserUpdate',
      async (
        roomID: any,
        updateType: any,
        streamList: any,
        extendedData: any
      ) => {
        console.log('roomUserUpdate')
      }
    )

    zg.on('playerStateUpdate', async (result: any) => {
      console.log('playerStateUpdate ', result)
    })
  }

  const loginRoom = async (zg: any, roomId: any, token: any, userId: any) => {
    try {
      const result = await zg.loginRoom(
        roomId,
        token,
        { userID: userId },
        { userUpdate: true }
      );
      if (result === true) {
        console.log('zego cloud login success');
        return true;
      }
    } catch (error) {
      console.log('error ', error);
      return false;
    }
  };
  async function getCurUser() {
    const res = await CurrentUser();
    if (res) {
      setCurUserData(res)
      setUserId(res.id)
    }
    console.log("####", res)

  }

  const startPublishingStream = async (deviceID: any, cameraStatus: any, zg: any, publishID: any, videoRef: any) => {
    if (zg) {

      let cam_options: any = false;
      console.log("DEVICE_ID", deviceID)
      //const localView = await zg?.createLocalStreamView(localStream);
      if (deviceID) {
        cam_options = {
          facingMode: 'user',
          width: 320,
          height: 180,
          frameRate: 15,
        }
      }

      const V_options = {
        camera: {
          audio: true,
          video: cam_options,
          AEC: true,
          ANS: true,
          AGC: true,
          channelCount: 2,
        }
      };




      const localStream: any = await zg?.createZegoStream(V_options);




      videoRef.innerHTML = '';
      videoRef.style.borderRadius = '5px'
      videoRef.style.overflow = 'hidden'
      videoRef.style.height = 'stretch'

      localStream.playCaptureVideo(videoRef);

      console.log("LOCAL_STREAM_INNER", localStream, publishID)
      await zg.startPublishingStream(publishID, localStream);
      if (deviceID) {
        await zg.useVideoDevice(localStream, deviceID);
        await zg.updatePublishingStream(localStream, 0);
      }
      return localStream;


    }
  };

  const logoutRoom = async (zg: any, roomId: any, publishId: any, localStream: any) => {
    localStream.stopCaptureVideo();
    await zg.stopPublishingStream(publishId);
    await zg.logoutRoom(roomId);
  }
  
  // const selectMicrophone = (deviceId: string, localStream: any, zg: any) => {
  //   if (deviceId) {
  //     zg.useAudioDevice(localStream, deviceId);
  //   }
  // };

  // const selectMuteMicrophone = (muteState: boolean, zg: any) => {
  //   zg.muteMicrophone(muteState);
  // };
  // const selectSpeaker = (deviceId: string, audioElement: any, zg: any) => {
  //   if (deviceId) {
  //     try {
  //       zg.useAudioOutputDevice(audioElement, deviceId);
  //       audioElement?.play();
  //     } catch (error) {
  //       console.error('Detect speaker fail:', error);
  //     }
  //   }
  // };

  // const selectCamera = (deviceId: string, localStream: any, zg: any) => {


  //   if (deviceId) {
  //     try {
  //       console.log('DEVICE ID', deviceId)
  //       zg.useVideoDevice(localStream, deviceId)
  //     } catch (error) {
  //       console.error('Detect camera fail:', error);
  //     }
  //   }
  // }

  // const selectMuteCamera = (cameraStatus: boolean, zgLocStream: any, zgLocView: any, zg: any, publishID: any) => {
  //   try {
  //     if (!cameraStatus === true) {
  //       let localVid = document.getElementById('localVideo');

  //       zgLocStream.playCaptureVideo(localVid);
  //       zg.updatePublishingStream(zgLocStream, 0)
  //       console.log('played')
  //     } else {

  //       zgLocStream.stopCaptureVideo();
  //       zg.updatePublishingStream(zgLocStream, 0)
  //       console.log('stopped')
  //     }

  //   } catch (error) {
  //     console.error('Detect camera mute fail: ', error)
  //   }
  // }



  const zegoCloud = async (localVideoRef: HTMLElement | null, currentUserData: any) => {
    const { zg } = zegoCloudCred;
    const player: HTMLElement | null = document.querySelector('#music-player');
    //const remoteUsersBlock = document.querySelector('#videoRemoteUsers')


    console.log("LOC_USER", currentUserData)


    const publishID = `stream-${currentUserData.id}`

    console.log("LOC_INIT", isLocalStreamInitialized)
    console.log("LOC_CAMERA", cameraDeviceSelected)
    console.log("LOC_PUBLISH", publishID)

    if (!isLocalStreamInitialized && (publishID !== 'stream-undefined')) {

      const zegoCloudStreamObj = await startPublishingStream(cameraDeviceSelected, muteCamera, zg, publishID, localVideoRef);
      setLocalStream(zegoCloudStreamObj)



      if (zegoCloudStreamObj && !isLocalStreamInitialized) {

        console.log("LOCAL STREAM", zegoCloudStreamObj)
       
        //setAudioElement(player);
        console.log("###", localStream)


        //if (speakerDeviceSelected) selectSpeaker(speakerDeviceSelected, player, zg)
        if (microphoneDeviceSelected) selectMicrophone(microphoneDeviceSelected, localStream, zg);
        selectCamera(cameraDeviceSelected, localStream, zg);
        selectMuteMicrophone(muteMicrophone, zg)
        selectMuteCamera(muteCamera, zegoCloudStreamObj.localStream, zg)


      }
    }
    return (true)
  }

  /*const zegoCloudLocStream = async (cameraStatus: any, zg: any, publishID: any, videoRef: any) => {
    if (zg) {
      const localVStream: any = await zg?.createZegoStream({
        camera: {
          audio: true,
          video: true,
          AEC: true,
          ANS: true,
          AGC: true,
          channelCount: 2,
        },
      });
      const localAStream: any = await zg?.createZegoStream({
        camera: {
          audio: true,
          video: false,
          AEC: true,
          ANS: true,
          AGC: true,
          channelCount: 2,
        },
      });

      //const localView = await zg?.createLocalStreamView(localStream);

      if (!cameraStatus) {
        videoRef.innerHTML = '';
        if (!videoRef.hasChildNodes()) {
          localVStream.playCaptureVideo(videoRef);
        }
        zg.stopPublishingStream(publishID);
        zg.startPublishingStream(publishID, localVStream);
        return { localVStream };
      } else {


        videoRef.innerHTML = '';
        localAStream.playVideo(videoRef);
        zg.stopPublishingStream(publishID);
        zg.startPublishingStream(publishID, localAStream);

        return { localAStream };
      }

    }
  };
  */


  useEffect(() => {
    console.log("$887", socketData)

    socketData.onmessage = function (event: any) {

      const eventData = JSON.parse(event.data);
      console.log("#EVENT", eventData)
      switch (eventData.type) {
        case 'USER_JOINED_ROOM': {
          let joinedUser = { name: eventData.userName, sessionRole: eventData.userSessionRole, userId: eventData.userId, muted: eventData.muted, deafened: eventData.deafened }
          let updParticipantsList = { ...participants, ...joinedUser }
          setParticipants(updParticipantsList)
          break;
        }
        case 'USER_LEFT_ROOM': {
          const updParticipantsList = participants.filter((item: IParticipantSocket) => eventData.userId !== item.userId)
          setParticipants(updParticipantsList)
          break;
        }
        case 'USER_DISCONNECTED_ROOM': {
          const newParticipantsList = participants.filter((item: IParticipantSocket) => eventData.userId !== item.userId)
          setParticipants(newParticipantsList)
          break;
        }
        case 'PARTICIPANTS_REMOVED': {
          if (eventData.userIds.includes(userId)) {
            
            navigate('/logout')
          }
          else {
            const newParticipantsList = participants.filter((item: IParticipantSocket) => {
              // Check if the item's userId is not included in the userIdsToRemove array
              return !eventData.userIds.includes(item.userId);
            });
            setParticipants(newParticipantsList)
          }
          break
        }
      }
    }

  }, [socketData, participants]);

  useEffect(() => {
    if (curUserData) {

      socketData.onmessage = function (event: any) {
        const eventData = JSON.parse(event.data);

        console.log("EVENT_DATA", eventData)
        if (eventData.type === 'USERS_AUDIO_IO_UPDATED') {

          let user_array = eventData.userIds

          console.log('$$$', curUserData.id, user_array);
          if (user_array.includes(curUserData.id) && eventData.muted !== undefined) {
            console.log('MUTE', eventData.muted)
            selectMuteMicrophone(eventData.muted, zegoCloudCred.zg);
            setMuteMicrophone(eventData.muted)

          }
          if (user_array.includes(curUserData.id) && eventData.deafened !== undefined) {
            console.log('DEAFEN', eventData.deafened)
            setMuteSpeakers(eventData.deafened)

          }
        }
        if (eventData.type == 'PARTICIPANTS_REMOVED') {
          console.log("PARTICIPANTS_REMOVED", eventData.userIds, userId)
          if (eventData.userIds.includes(userId)) {
            //logoutRoom(zegoCloudCred.zg, roomId, `stream-${curUserData.id}`, localStream)

            console.log("LOCAL_STREAM_REMOVE", localStream)
            
            navigate('/logout')
          }

        }
        if (eventData.type == 'USER_DISCONNECTED_ROOM') {
          console.log("USER_DISCONNECTED_ROOM", eventData.userId, userId)
          if (eventData.userId == userId ) {
            //logoutRoom(zegoCloudCred.zg, roomId, `stream-${curUserData.id}`, localStream)

           
            navigate('/logout')
          }
        }

        //{type: 'ROOM_CLOSED', correlationId: '23d582a3-4506-45e6-9b65-acebfcee9371', roomCloseReason: 'ORGANIZER_CLOSED_MANUALLY'}

        if (eventData.type == 'ROOM_CLOSED') {
          
          //logoutRoom(zegoCloudCred.zg, roomId, `stream-${curUserData.id}`, localStream)
          console.log("ROOM_CLOSED", eventData.roomCloseReason)
          navigate('/logout')

        }

      }


    }
    else {
      getCurUser()
    }

  }, [socketData, curUserData])

  useEffect(() => {
    const login = async () => {
      if (!loggedInZg) {
        if (zegoCloudCred && roomId && sessionData) {
          try {
            const val = await loginRoom(zegoCloudCred.zg, roomId, zegoCloudCred.token, zegoCloudCred.userId);
            console.log("ZG_LOGIN_RESPONSE", val);

            setLoggedInZg(val);
          } catch (error) {
            console.error("Error logging in:", error);
          }
        } else {
          console.log("FAIL_LOGIN_ZG")
        }
      }
    };
    zegoCloudEvents(zegoCloudCred.zg);
    login();

    return () => {
      zegoCloudCred.zg.stopPublishingStream(`stream-${userId}`)
      zegoCloudCred.zg.logoutRoom(roomId);
      zegoCloudCred.zg.destroyEngine();
      zegoCloudCred.zg = null;
    };
  }, []);

  useEffect(() => {

    //setCurrentUserData(sessionData?.participants.filter((item: IParticipant) => item.email === email && item)[0]);
    console.log("CURRENT_SESSION_DATA", sessionData)
    if (!curUserData) {
      console.log("FAIL_CURRENT_USER_DATA", curUserData)
      getCurUser()

    }
    if (!roomId) {
      console.log("FAIL_ROOM_ID", roomId)
      alert("No room id, please try again")
      //handle no room id
    }
    if (roomId && curUserData && loggedInZg) {
      console.log("@_SUCCESS", roomId, curUserData, cameraDeviceSelected)
      //curUserData, roomId and cameraDeviceSelected exist
      setIsLoaded(true);

    }
    if (isLoaded) {
      console.log("LOADED")
      zegoCloud(localVideoRef.current, curUserData).then((result) => {
        setIsLocalStreamInitialized(result);

      })

    }
    // return (
    //   log out of zego
    // );
  }, [sessionData, curUserData, roomId, cameraDeviceSelected, isLoaded, participants, remoteStreams, loggedInZg]);


  // useEffect(() => {
  //   console.log("PARTICIPANTS_##", participants)
  //   zegoCloudCred.zg.updatePublishingStream(localStream, 0)

  // }, [cameraDeviceSelected, muteCamera, localStream, remoteStreams])

  const findParticipantIndex = (remoteStream: any) => {
    // Extract userId from the streamID
    const userId = remoteStream.streamID.split('-')[1];

    // Find the index of the participant with this userId
    const index = participants.findIndex((participant: any) => participant.userId === userId);

    if (index === -1) { console.log('Participant not found'); return null }
    else
      return index; // This will be -1 if no matching participant is found
  };

  return (

    <div style={{ width: 'stretch', height: 'stretch' }}>
      {/* <Participants/> */}
      {isLoaded && <>
        <div className={`${isListOfParticipants ? "h-full" : "hidden"}`} style={{ margin: '20px 0px 20px 20px', width: 'fit-content', overflowX: 'hidden', display: 'flex', flexDirection: 'column', justifyContent: 'start' }}>
          
            <div id="localVideo" ref={localVideoRef}></div>
            <div style = {{display: 'flex', paddingLeft: '5px', paddingRight: '5px', transform: 'translate(0px, -38px)', alignItems: 'flex-end', justifyContent: 'space-between', flexDirection: 'row', width: 'stretch', marginBottom: '-38px'}}>
            <div style={{ zIndex: '10', textShadow: '2px 2px 2px black', transform: "translate(0px, 5px)"}}>
              {`${curUserData?.firstName ?? "Guest"} ${curUserData?.lastName ?? ""}`}
            </div>
            <div style={{ zIndex: '10', textShadow: '2px 2px 2px black', marginBottom: '2px' }}>
              {muteMicrophone ?
                <img src="/icons/micOff.svg" alt="muted"
                style={{ width: '33px', height: '33px', padding: '2px', backgroundColor: "#3f3f3f", borderRadius: "100px",  border: '2px solid #FB6520' }} /> : 
                <img src="/icons/micOn.svg" alt="unmuted" 
                style={{ width: '33px', height: '33px', padding: '3px', backgroundColor: "#3f3f3f", borderRadius: "100px",  border: '2px solid #2DBF81' }} /> }
            </div>
            
          </div>

          {remoteStreams?.map((remoteStream: any) => (
            <div key={remoteStream.streamID}>
              <RemoteVideoComponent
                key={remoteStream.streamID}
                stream={remoteStream}
                zegoCloudCred={zegoCloudCred}
                participantInfo={participants[findParticipantIndex(remoteStream)]}
                
              />
            </div>
          ))}
        </div>
        <div id="videoUsers">

        </div>
      </>
      }
    </div>
  );


}


export default ZegoParent;