import React, {useEffect, useRef, useState} from 'react';

interface IDeviceConfig {
  deviceID: string,
  deviceName: string
}

export default function ArrowDropdown(props: Readonly<{dataDropdown: Array<IDeviceConfig>, handle: Function}>) {
  const { dataDropdown, handle } = props;
  const [stateDrdopdown, setStateDrdopdown] = useState(false);
  const [selectedDeviceName, setSelectedDeviceName] = useState<string>('');
  const dropdownRef = useRef<any>(null);

  const toggleDropdown = () => {
    setStateDrdopdown(!stateDrdopdown);
  };

  const closeDropdown = () => {
    setStateDrdopdown(false);
  };

  const handleDropdown = (item: IDeviceConfig) => {
    setSelectedDeviceName(item.deviceName);
    handle(item.deviceID);
    closeDropdown();
  };

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      const current = dropdownRef.current
      if (current && !current.contains(event.target)) {
        closeDropdown();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative"  ref={dropdownRef}>
      <button
        onClick={toggleDropdown}
        className="flex flex-row items-center justify-center bg-[#313139] h-full rounded text-left hover:cursor-pointer"
      >
        <img
          src="/icons/dropdownArrow.svg"
          alt="fullscreen"
          width={9}
          height={5}
        />
      </button>
      {stateDrdopdown ? (
        <ul className="absolute bg-[#313139] w-[250px] rounded z-50 bottom-[35px] left-[-9px] max-h-[300px] md:max-h-[250px] sm:max-h-[180px] overflow-y-scroll">
          {dataDropdown?.map((item: IDeviceConfig) => (
            <li
              className="px-4 py-2 hover:bg-[#313180]"
              key={item.deviceID}
            >
              <button className="text-left" onClick={() => handleDropdown(item)}>
                {item.deviceName}
              </button>
            </li>
          ))}
        </ul>
      ) : null}
    </div>
  );
}
