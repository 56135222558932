import React, { useContext, useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { StreamContext, InterfaceContext, UserContext, SocketContext } from 'context';
import {
  StreamWindow,
  StreamNavigation,
  Participants,
} from 'ui/components/index.module';
import {
  selectMicrophone,
  selectMuteMicrophone,
  selectMuteCamera,
  selectSpeaker,
 
  loginRoom,
  
  selectCamera
} from 'utils/zegoCloud';
import { IZegoCloudStreamObj, IParticipant } from 'types';
import { useConnectStream, useGetCurrentUser } from 'hooks/useServerQueries';
import ZegoParent from 'utils/zegoParentComponent';
export default function Stream() {
  const { mutateAsync: CurrentUser } = useGetCurrentUser()
  
  const { isListOfParticipants } = useContext(InterfaceContext);
  const {
    zegoCloudCred,
    roomId,
    speakerDevices,
    microphoneDevices,
    microphoneDeviceSelected,
    speakerDeviceSelected,
    cameraDeviceSelected,
    setCameraDeviceSelected,
    setAudioElement,
    muteMicrophone,
    muteCamera,
    setLocalStream,
    localStream,
    sessionData,
    
    dolbyStreamingConfig,
    setDolbyStreamingConfig
    
  } = useContext(StreamContext);
  const [doCapture, setDoCapture] = useState(true);
  const { participants } = useContext(SocketContext);
  const navigate = useNavigate();
  const [curUserData, setCurUserData] = useState<any>(null);
  const { sessionIdFromParams,  } = useContext(UserContext);
  const { mutateAsync: connectStream } = useConnectStream(sessionIdFromParams);
  const email = localStorage.getItem('userEmail');
  const currentUserData = sessionData?.participants.filter((item: IParticipant) => item.email === email && item)[0];
  console.log("$$&&", sessionData)
  const localVideoRef = useRef(null)

  // const zegoCloud = async (localVideoRef) => {
  //   const { zg } = zegoCloudCred;
  //   const player: HTMLElement | null = document.querySelector('#music-player');
  //   const remoteUsersBlock = document.querySelector('#videoRemoteUsers')
    


  //   if (zg) {zegoCloudEvents(zg, remoteUsersBlock, sessionData?.participants)}

  //   loginRoom(zg, roomId, zegoCloudCred?.token, zegoCloudCred?.userId)
    
  //   const publishID = `stream-${currentUserData.userId}`

  //   const zegoCloudStreamObj: IZegoCloudStreamObj | undefined = await zegoCloudLocStream(muteCamera, zg, publishID, localVideoRef);
    
    
   

  //   if (zegoCloudStreamObj?.localStream && !isLocalStreamInitialized) {
  //     const { localStream, localView } = zegoCloudStreamObj;
  //     console.log("LOCAL STREAM", localStream)
  //     setLocalStream(localStream)
  //     //setAudioElement(player);

      

  //     if (speakerDeviceSelected) selectSpeaker(speakerDeviceSelected, player, zg)
  //     if (microphoneDeviceSelected) selectMicrophone(microphoneDeviceSelected, localStream, zg);
  //     if (cameraDeviceSelected) selectCamera(cameraDeviceSelected, localStream, zg);
  //     selectMuteMicrophone(muteMicrophone, zg)
  //     selectMuteCamera(muteCamera, zegoCloudStreamObj.localStream, localView, zg, publishID)
    
    
  //   }
  //   setIsLocalStreamInitialized(true)
  //   return (true)
  // }

  async function getStreamConfig(){
    const res = await connectStream(sessionIdFromParams)
    console.log("&&22", res)
    await setDolbyStreamingConfig(res);
  }

  useEffect(() => {
    if (!dolbyStreamingConfig) {
      getStreamConfig();
    }
 
  },[dolbyStreamingConfig, sessionData])

  async function getCurUser(){
    const res = await CurrentUser();
    await setCurUserData(res);
    console.log("####", res)
  
  }

 

  
  
  

  
  return (
    <div className="flex flex-col px-4 py-4 h-screen w-screen justify-center items-center overflow-hidden">
      <div className="h-full w-full">

        <div className="flex flex-row justify-between h-[calc(100%-42px-2rem)]">
          <div className={`${isListOfParticipants ? 'w-full' : 'w-full'}`}>
            <StreamWindow />
          </div>
          <div className={`${isListOfParticipants ? " h-full" : "hidden"}`} style = {{overflowY: "auto", justifyContent: 'center'}}>
           {/*<div style = {{height: '200px'}}>
          <div id="localVideo" ref = {localVideoRef} style = {{height: '150px'}}>

          </div>
          {`${curUserData?.firstName?curUserData.firstName:"Guest"} ${curUserData?.lastName?curUserData.lastName:""}`}
          </div>
            <video controls className="hidden" id="music-player" />
           
            <div id="videoRemoteUsers"></div> */}
           {sessionData && <ZegoParent/>}
          </div>
        
          
        </div>
        <StreamNavigation />
      </div>
    </div>
  );
}
