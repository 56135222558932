import React, {ChangeEvent, HTMLInputTypeAttribute, useState} from 'react';
import FormInput from "../FormInput";
import Label from "../../Label";
import {EyeButton} from "../../index.module";

interface LabelInputProps {
  textLabel: string,
  name: string;
  type: HTMLInputTypeAttribute;
  value: string;
  className?: string;
  style?: React.CSSProperties;
  placeholder?: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  children?: JSX.Element
}

export default function LabelInput ({ textLabel, name, type, value, onChange, className, style, placeholder, children }: LabelInputProps) {
  const [showPassword, setShowPassword] = useState(true);

  return (
    <div style = {{flexDirection: 'column', width: '100%'}}>
      <div className="flex justify-between items-center mb-2">
        <Label text={textLabel}/>
        {children && children}
      </div>

      {(name === "password" || name === "confirmPassword") ?
        <div className="relative">
          <FormInput
              name={name}
              type={showPassword ? 'password' : 'text'}
              value={value}
              onChange={onChange}
              className={`shadow-lg appearance-none bg-[#313139] rounded-lg w-full py-4 px-3 text-sm leading-tight focus:outline-none ${
                  showPassword ? 'body-large-17-regular placeholder-17px' : 'font-[200] text-gray-300'} ${className}`}
              style={{boxShadow: 'inset 2px 2px 6px rgba(0, 0, 0, 0.3)',
                ...(showPassword ? { '--body-large-text-color': 'var(--Swiss-Coffee)' } : {}), ...style}}
              placeholder={placeholder}
          />
          <EyeButton setShowEye={setShowPassword} showEye={showPassword} styles='top-2' />
        </div> : <div>
            <FormInput
                name={name}
                type={type}
                value={value}
                onChange={onChange}
                className={className}
                style={style}
                placeholder={placeholder}
            />
        </div>}
    </div>
  )
}
