export function convertStreamTypeToValue(type: string): string {
  const data: { [key: string]: string} = {
    JOIN_REQUEST_INTENT: 'Sent Request to Join',
    STREAM_READY: 'Stream is ready',
    JOIN_REQUEST_ALLOWED: 'Access to the meeting allowed',
    USER_DISCONNECTED_ROOM: 'User was disconnedcted from the room',
    READY_TO_START: 'Ready to start'
  };
  return data[type];
}

export function converStreamStateToValue(type: string): string {
  const data: { [key: string]: string} = {
    READY_TO_START: 'Ready to start',
    STARTED: 'Started',
  };
  return data[type];
}

export function convertRoleTypeToValue(type: string): string {
  const data: { [key: string]: string} = {
      RP_ORGANIZER: 'Organizer',
      RP_GUEST: 'Guest',
  };
  return data[type];
}
