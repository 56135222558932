import React, { useState } from 'react';

export default function SecondaryButton(props: Readonly<{ text: string, styles?: string, click?: any }>) {
  const { text, styles, click } = props;
  const [isHovered, setIsHovered] = useState(false);
  const [isPressed, setIsPressed] = useState(false);

  const buttonClassName = `bg-[var(--Tuna)] ${
    isHovered ? 'hover:bg-[var(--Tuna)] active:bg-[var(--Tuna)]' : ''
  } ease-in-out duration-100 cursor-pointer text-[17px] text-#FFFFFF py-2 px-6 shadow-secondary-button rounded-[8px] ${
    isPressed ? 'pressed-state-class' : ''
  }`;

  const pressedStateStyles = {
    borderRadius: '8px',
    boxShadow: '2px 2px 2px 0px rgba(0, 0, 0, 0.25) inset, -2px -2px 2px 0px rgba(221, 216, 216, 0.20) inset',
    opacity: isPressed ? '50%' : '100%',
  };

  return (
    <button
      type="button"
      className={`${buttonClassName} ${styles}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onMouseDown={() => setIsPressed(true)}
      onMouseUp={() => setIsPressed(false)}
      onClick={click}
      style={isPressed ? pressedStateStyles : {}}
    >
      {text}
    </button>
  );
}
