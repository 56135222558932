import React, {useState, ReactNode, useMemo, useEffect} from 'react';
import { UserContext } from 'context/';
import { useRefreshToken } from "../hooks/useServerQueries";

type UserContextProviderWrapperProps = { children: ReactNode };

function UserContextProviderWrapper({
  children,
}: UserContextProviderWrapperProps) {
  const [sessionId, setSessionId] = useState<string | null>(null);
  const [isNewAccount, setNewAccount] = useState<boolean | 'init'>(false);
  const [sessionIdFromParams, setSessionIdFromParams] = useState<
    string | string[] | undefined
  >('');
  const [userId, setUserId] = useState<string | null>(null);

  const values = useMemo(
    () => ({
      setSessionId,
      sessionId,
      setSessionIdFromParams,
      sessionIdFromParams,
      setNewAccount,
      isNewAccount,
      setUserId, 
      userId
    }),
    [
      sessionId,
      sessionIdFromParams,
      isNewAccount,
      userId
    ],
  );

  return <UserContext.Provider value={values}>{children}</UserContext.Provider>;
}

export default UserContextProviderWrapper;
