import React from 'react'

export default function IconText(props: Readonly<{ text: string, imgSrc: string, imgAlt: string, classesBlock?: string }>) {
  const {text, imgSrc, imgAlt, classesBlock} = props;

  return (
    <div className={`flex flex-row items-center ${classesBlock}`}>
      <img
        src={imgSrc}
        alt={imgAlt}
        width={25}
        height={25}
        className="mr-2"
      />
      <p className="body-rf-12">{text}</p>
    </div>
  )
}
