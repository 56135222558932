import React from 'react';

interface Subtitle16Props {
  text: string;
}

const Subtitle16: React.FC<Subtitle16Props> = ({ text }) => (
  <div className="flex flex-col items-center gap-3 py-6 px-10">
    <h1 
      className="subtitle-rf-16 text-center w-[430px]"
      style={{ '--subtitle-text-color': 'var(--Swiss-Coffee)' }}>
      {text}
    </h1>
  </div>
);

export default Subtitle16;
