import React from 'react';

interface LabelProps {
  text: string;
  className?: string;
  style?: React.CSSProperties;
}

export default function Label({ text }: Readonly<LabelProps>) {
  return (
    <h1
      className="subtitle-rf-16"
      style={{ '--subtitle-text-color': 'var(--Swiss-Coffee)' }}>
      {text}
    </h1>
  );
}
