import React, {useContext} from "react";
import { Outlet, Navigate } from "react-router-dom";
import {AuthContext} from "../../context";

export default function PrivateRoutes() {
  const { authToken } = useContext(AuthContext);

  return (
    authToken ? <Outlet /> : <Navigate to='/auth' />
  )
}
