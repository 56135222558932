import React, { useContext, useState } from 'react';
// import { Label } from "@remotopro/remoto-ui-components"
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useLogin } from 'hooks/useServerQueries';
import {AuthContext, UserContext} from 'context/';
import {
  ErrorMessage,
  PrimaryButton,
  LabelInput,
  LinkButton,
} from '../../components-library/index.module';
import { FormWrapper } from '../../wrappers/FormWrapper';
import { authSteps } from 'constants/';
import '../../../styles/globals.scss';

/**
 * * Email and password focussed has been implemented
 * * Old commented code removed
 * * Link to network test, also placeholders on email and password has been implemented.
 * * UI styles updates implemented in multiple places to follow global scss
 * ! Entry point to create a new account has been commented for furute reuse
 */

export default function LoginModal() {
  const { mutateAsync: Login } = useLogin();
  const {
    setSessionId,
    setNewAccount,
  } = useContext(UserContext);
  const {
    setAuthToken,
    setAuthStep
  } = useContext(AuthContext)

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate()

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: async (values) => {
      const { email, password } = values;
      const payload = {
        email,
        password,
        solution: 'RP',
      };

      try {
        setAuthToken(null);
        localStorage.removeItem('token');
        const res = await Login(payload);

        if (res.status === 200) {
          setShowSuccessMessage(true);

          // Set a timeout to hide the message and navigate
          setTimeout(() => {
            setShowSuccessMessage(false);

            // Further action based on the challenge
            if (res.challenge === 'NONE') {
              setAuthToken(res.token);
              setTimeout(() => navigate(`/checkSound`), 2000); // Delayed navigation
            } else if (res.challenge === 'LOGOUT_FROM_OTHER_DEVICES') {
              setSessionId(res.sessionId);
              setAuthStep(authSteps.CHALLENGE);
            } else if (res.challenge === 'CHANGE_PASSWORD') {
              setSessionId(res.sessionId);
              setNewAccount(true);
              setAuthStep(authSteps.NEW);
            } else {
              setTimeout(() => navigate('/auth'), 500); // Delayed navigation
            }
          }, 1000); // Delay for showing the success message

        } else if (res.status == 401) {
          setAuthToken(null);
          localStorage.removeItem('token');
          setShowSuccessMessage(false);
          setErrorMessage(res.description);
          console.log("LOGINError: ", res.description);
        }
      } catch (error) {
        setShowSuccessMessage(false);
        setErrorMessage('An unexpected error occurred');
      }
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .email('Must be a valid email')
        .required('Email is required'),
      password: yup
        .string()
        .min(8, 'Password is too short - should be 8 chars minimum.')
        .matches(/[a-z]/, 'Password must contain at least one lowercase letter.')
        .matches(/[A-Z]/, 'Password must contain at least one uppercase letter.')
        .matches(/[^A-Za-z0-9]/, 'Password must contain at least one special character.')
        .matches(/[0-9]/, 'Password must contain at least one number.')
        .required('Password is required'),
    }),
  });

  const forgotPassword = () => {
    setAuthStep(authSteps.FORGET);
  };

  const resendPassword = () => {
    setAuthStep(authSteps.RESEND)
  };

  return (
    <div className="modal-container">
      <div className="modal-content">
        <FormWrapper>
          <form onSubmit={formik.handleSubmit}>
            <div className="border-b-[1px] border-[#48485C] pt-4 pb-8 text-[20px] font-[700] px-10">
              <div className="mb-6">
                <LabelInput
                  textLabel='Email'
                  name="email"
                  type="email"
                  className={`body-large-17-regular shadow-lg appearance-none bg-[#313139] rounded-lg w-full py-4 px-3 text-sm leading-tight focus:outline-none`}
                  placeholder="user@example.com"
                  style={{ boxShadow: 'inset 2px 2px 6px rgba(0, 0, 0, 0.25)', '--body-large-text-color': 'var(--Swiss-Coffee)' }}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                />
              </div>
              <div className="mb-6">
                <LabelInput
                  textLabel='Password'
                  name="password"
                  type='password'
                  placeholder="••••••••••••••••"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                >
                  <div className="flex items-center gap-2">
                    <LinkButton text='Forgot Password' click={forgotPassword} />
                  </div>
                </LabelInput>
                {(formik.errors.email ||
                  formik.errors.password ||
                  errorMessage) && (
                  <ErrorMessage
                    text={
                      formik.errors.email || formik.errors.password || errorMessage
                    }
                  />
                )}
              </div>
            </div>
            <div className="py-4 px-10 flex justify-between items-center">
                <img  style = {{width: '35px', margin: '-40px 0px -40px 0px'}} src = {"/icons/info.svg"} /> 
                <p style = {{lineHeight: 'normal', fontSize: '10px' , width: '300px'}} > {"Invited to a session? Use the provided sign-in credentials. Can't find them, of they've expired? Click"} 
                <span onClick = {resendPassword} style = {{color: "var(--Orange"}}> here </span> 
                to receive new ones. </p> 
               
              <PrimaryButton text='Sign In' />
            </div>
                {showSuccessMessage && (
                <div className="success-message">Submitted</div> // Place the success message here
                )}
          </form>
        </FormWrapper>
        <div className="additional-text">
        <p
          className="body-rf-12"
          style={{ '--body-text-color': 'var(--Manatee)' }}>
          Not sure about your network settings before streaming?{' '}
          <a
            href="https://stage.remotopro.io/test"
            className="body-rf-12"
            style={{ '--body-text-color': 'var(--Orange)' }}
            target="_blank" rel="noopener noreferrer">
            Run Network Test here.
          </a>
        </p>
      </div>
    </div>
  </div>
  );
}
