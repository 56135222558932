import React, { useContext, useEffect, useRef, useState } from 'react';
import { Director, View } from '@millicast/sdk';
import { useGetCurrentUser, useWatermark } from "../../../../hooks/useServerQueries";
import { IDolbyConfig } from 'types/';
import { StreamContext } from 'context/';
import {millicast} from "../../../../utils/milicast";


export default function StreamElement() {
  const { dolbyStreamingConfig, streamState, roomData, muteSpeakers, speakerDeviceSelected } =
    useContext(StreamContext);
  const videoEl = useRef<any>(null);
  const [val, setVal] = useState<MediaStream>();
  const sessionId = localStorage.getItem('sessionId')
  const { mutateAsync: Watermark } = useWatermark(sessionId);
  const { mutateAsync: CurrentUser } = useGetCurrentUser()
  const [watermarkConfig, setWatermarkConfig] = useState<any>()
  const [currentUserData, setCurrentUserData] = useState<any>()
  const [volume, setVolume] = useState(0); // State for volume level

  const watermark = async () => {
      if (sessionId) {
        const watermarkConfig = await Watermark(sessionId)
        if (watermarkConfig) {
          const currentUserData = await CurrentUser();
          setWatermarkConfig(watermarkConfig)
          setCurrentUserData(currentUserData)
        }
      }
    }
  const VolumeMeter = ({ volume }: { volume: number }) => {
    return <div style={{ position: 'absolute', width: `${volume}%`, backgroundColor: 'green', height: '20px' }} />;
  };
  useEffect(() => {
    watermark()
  }, []);

  useEffect(() => {
    console.log('dolbyStreamingConfig', dolbyStreamingConfig)
    if (dolbyStreamingConfig) {
      millicast(dolbyStreamingConfig, videoEl)/*.then(() => {
      
        // VOLUME METER SHOULD BE ENCAPSULATED IN A FUNCTION
        const audioContext = new AudioContext();
        const analyser = audioContext.createAnalyser();
        analyser.fftSize = 256;
        const source = audioContext.createMediaStreamSource(videoEl.current.srcObject);
        source.connect(analyser);
        source.connect(audioContext.destination);

        const data = new Uint8Array(analyser.frequencyBinCount);

        const updateVolume = () => {
          analyser.getByteFrequencyData(data);
          let sum = 0;
          for (let i = 0; i < data.length; i++) {
            sum += data[i];
          }
          let average = sum / data.length;
          setVolume(average); // Update volume state
          requestAnimationFrame(updateVolume);
        };

        updateVolume();
      });*/
    }
  

  }, [dolbyStreamingConfig, videoEl, ]);

  useEffect(()=>{
    if(speakerDeviceSelected !==videoEl.current.sinkId){
      videoEl.current.setSinkId(speakerDeviceSelected).catch((err:any) => console.error('Error setting audio output device:', err));
      }
  }, [speakerDeviceSelected])

  useEffect(()=>{
    videoEl.current.muted = muteSpeakers
},[muteSpeakers])

  return (
    <div className="h-[calc(100%-(40px+36px+76px))]">
      
      <div className="relative flex items-center justify-center w-full h-full" style = {{backgroundColor: 'black'}}>
        <video
            ref={videoEl}
            id="videoMain"
            autoPlay
            style = {{width: '100%', height: '100%'}}
        />
        {/*<VolumeMeter volume={volume} />  Render the volume meter */}

        {(currentUserData && watermarkConfig) &&
            <div className="absolute top-0 left-0 w-full h-full">
              <p className="text-center textt-xl pt-2">{`${currentUserData?.firstName} ${currentUserData?.lastName} ${watermarkConfig?.ipOverlay && roomData?.ownExternalIp}`}</p>
            </div>
        }
      </div>
    </div>
  );
}
