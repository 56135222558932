import axios from 'axios';

// @ts-ignore
export const axiosConfig = axios.create({
  "baseURL": `${process.env.REACT_APP_API_URL}`,
  "headers": {
    "Accept": "application/json",
    'Content-Type': "application/json",
    "SolutionContext": "RP",
  },
});

export const SetupInterceptors = (navigate: any) => {
  axiosConfig.interceptors.response.use(response => {
    return response;
  }, error => {
  if (error.response.status === 401) {
    localStorage.removeItem('token')
    //localStorage.removeItem('sessionId')
    navigate('/auth');
  }
  return error;
  });
}

export const wrapAxiosResponse = async <T>(
  action: ReturnType<typeof axios.get> | ReturnType<typeof axios.post> | ReturnType<typeof axios.put>
): Promise<T> => {
  try {
    let res: any = await action;
    if (res.response) {
      return res.response.data
    } else if (res.data) {
      return {...res.data, status: res.status} as T
    }
    return res
  } catch (err) {
    console.log('wrapAxiosResponse ', err)
    throw new Error(`wrapAxiosResponse ${err}`)
  }
}
