import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { leavePayload } from 'constants/';
import { StreamContext, SocketContext } from 'context/';
import { getDate } from 'utils/parseDate';

export default function JoinSessionModal () {
    const { socketData } = useContext(SocketContext);
    const { sessionData, organizer } = useContext(StreamContext);
    const [timeSession, setTimeSession] = useState<string>('');
    const navigate = useNavigate();

    const leaveSession = () => {
        if (socketData) socketData.send(JSON.stringify(leavePayload));
        navigate('/logout');
    };

    useEffect(() => {
        if (sessionData) setTimeSession(getDate(sessionData.startDateTime, sessionData.duration));
    }, [sessionData])

    return (
        <div className='bg-[#202020] border rounded-md px-6 py-6 w-[550px]'>
            <p className='text-xl border-b border-whtie pb-4'>Join session</p>
            <div className="flex flex-col items-center border-b border-whtie">
                <img
                    className='my-6 rounded'
                    src="/logo/Loading-FullLogo.gif"
                    alt="logo"
                    width={120}
                    height={120}
                />
                <p className="text-2xl text-center mb-2 capitalize">
                    {sessionData.name}
                </p>
                <p className="text-xl text-center mb-4 capitalize">
                    Organizer - {organizer?.firstName} {organizer?.lastName}
                </p>
                <p className="text-center mb-2">{timeSession}</p>
                <p className='text-[#FB6520] mb-4'>Please wait, the session host will let you in soon</p>
            </div>
            <div className='flex flex-row w-full justify-end mt-4'>
                <button onClick={() => leaveSession()} className="bg-[#313139] hover:bg-[#44444D] ease-in-out duration-200 cursor-pointer text-[17px] text-white py-2 px-6 shadow-cancel-button rounded-[8px]">
                    Leave Session
                </button>
            </div>
        </div>
    )
}