import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {AuthContext, SocketContext, StreamContext, UserContext} from 'context/';
import { useLogout } from 'hooks/useServerQueries';

export default function Header() {
  const { mutateAsync: Logout } = useLogout();
  const {
    setSessionId,
    setSessionIdFromParams,
  } = useContext(UserContext);
  const {
    authToken,
    setAuthToken,
    setAuthStep,
  } = useContext(AuthContext);
  const { zegoCloudCred } = useContext(StreamContext);
  const { socketData } = useContext(SocketContext)
  const navigate = useNavigate()

  const logout = async () => {
    await Logout({ AccessToken: `${authToken}`, solution: 'RP' });
    if (socketData?.pingInterval) clearInterval(socketData.pingInterval);
    console.log("#REMOVE_SESSIONID_3")
    setAuthStep('');
    setAuthToken(null);
    setSessionId(null);
    setSessionIdFromParams(undefined);
    localStorage.removeItem('token');
    localStorage.removeItem('sessionId');
    localStorage.removeItem('userEmail');
    // zegoCloudCred.zg.destroyEngine();
    navigate('/auth');
  };

  return (
    <div className="flex flex-row w-full justify-between px-6 py-4 bg-[#313139]">
      <div className="flex items-center">
        <img src="/logo/RemotoLogo.png" alt="logo" width={148} height={26} />
      </div>
      <button
        className="flex justify-center items-center rounded-full bg-[#313139] shadow-md shadow-[#2B3134]-500/100 px-2 py-2"
        onClick={() => logout()}
      >
        <img src="/icons/logout.svg" alt="logout" width={20} height={20} />
      </button>
    </div>
  );
}
