import { createContext } from 'react';
import { IAuthContext } from 'types/context';

const authContextDefaults: IAuthContext = {
  setAuthToken: () => null,
  authToken: '',
  setAuthStep: () => null,
  authStep: '',
};

export const AuthContext = createContext<IAuthContext>(authContextDefaults);
