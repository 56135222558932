import React, {ReactNode, useEffect} from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import {useNavigate} from "react-router-dom";
import {SetupInterceptors} from "../utils/axios";

type ExtLibProviderWrapperProps = { children: ReactNode };

function ExtLibProviderWrapper({
  children,
}: ExtLibProviderWrapperProps) {
  const queryClient = new QueryClient();
  const navigate = useNavigate();

  useEffect(() => SetupInterceptors(navigate), [])

  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
}

export default ExtLibProviderWrapper;
