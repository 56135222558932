import React, {ChangeEvent} from 'react';

interface LinkButtonProps {
  text: string,
  click: () => void;
}

export default function LinkButton ({text, click}: LinkButtonProps) {
  return (
    <button
      type="button"
      className="body-rf-12 cursor-pointer hover:text-orange-400"
      style={{ '--body-text-color': 'var(--Orange)' }}
      onClick={click} // Make sure to define this function or replace it with the correct one
    >
      {text}
    </button>
  )
}
